<template>
  <div justify="center">
    <v-icon
      small
      @click.stop="openForm"
    >
      {{ icons.mdiPencil }}
    </v-icon>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span
            class="text-h5"
            style="color: red"
          >
            注文状態(取消)-編集
          </span>
        </v-card-title>
        <template v-if="apierror.status == 'error'">
          <div
            v-for="msg of apierror.messages"
            :key="msg"
          >
            <v-row class="ml-6 mb-3 ma-3">
              <span style="color: red">* {{ msg }} </span>
            </v-row>
          </div>
        </template>
        <v-card-text>
          <v-container>
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col
                cols="4"
              >
                <span>注文ID</span>
              </v-col>
              <v-col
                cols="8"
              >
                <span>{{ item.ord_id }}</span>
              </v-col>
              <v-col
                cols="4"
              >
                <span>注文番号</span>
              </v-col>
              <v-col
                cols="8"
              >
                <span>{{ item.order_no }}</span>
              </v-col>

              <v-col
                cols="4"
              >
                <span>会員氏名</span>
              </v-col>
              <v-col
                cols="8"
              >
                <span>{{ item.mbr_name }}</span>
              </v-col>

              <v-col
                cols="4"
              >
                <span>注文日時</span>
              </v-col>
              <v-col
                cols="8"
              >
                <span>{{ item.order_dt }}</span>
              </v-col>

              <v-col
                cols="4"
              >
                <span>キャンセル日時</span>
              </v-col>
              <v-col
                cols="8"
              >
                <span>{{ item.canceled_dt }}</span>
              </v-col>
              <v-col
                cols="12"
              >
                <v-autocomplete
                  v-model="item.os_code"
                  :items="OrderStatuses"
                  label="注文状態"
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row align="center">
            <v-col
              cols="4"
            >
            </v-col>
            <v-col
              cols="2"
            >
              <v-btn
                color="success"
                :loading="submitStatus"
                @click="submit()"
              >
                YES
              </v-btn>
            </v-col>
            <v-col
              cols="2"
            >
              <v-btn
                class="mx-2"
                color="error"
                @click="closeForm()"
              >
                NO
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiPencil,
} from '@mdi/js'
import {
  mapState, mapMutations, mapActions,
} from 'vuex'

// import Overlays from '@/components/Overlays.vue'

export default {
  components: {
    // Overlays,
  },
  props: ['id'],
  data: () => ({
    dialog: false,
    submitStatus: false,
    icons: {
      mdiPencil,
    },
    OrderStatuses: [],
    item: {
      ord_id: '',
      order_no: '',
      mbr_name: '',
      order_dt: '',
      canceled_dt: '',
      os_code: '',
    },
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
  }),
  computed: {
    ...mapState('app', ['absoluteOverlayStatus']),
    ...mapState('orderStore', ['selectedOrder', 'orderStatusMaster']),
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.$emit('refresh')
      }
    },
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus', 'setAbsoluteOverlayStatus']),
    ...mapMutations('orderStore', ['clearOrderStatusMaster', 'clearSelectedOrder']),
    ...mapActions('orderStore', ['loadOrder', 'loadOrderStatus', 'editCanceledStatus']),
    openForm() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadOrder(this.id),
        this.loadOrderStatus(),
      ]).then(() => {
        this.item = this.$deepCopy(this.selectedOrder)
        this.orderStatusMaster.forEach(osm => {
          if (osm.value === 1 || osm.value === 2 || osm.value === 99) {
            this.OrderStatuses.push(osm)
          }
        })
        console.log('OrderStatuses', this.OrderStatuses)
      }).finally(() => {
        this.setOverlayStatus(false)
        this.dialog = true
      })
    },
    closeForm() {
      this.dialog = false
      this.clearData()
    },
    clearData() {
      this.OrderStatuses = []
      this.item = {
        ord_id: '',
        order_no: '',
        mbr_name: '',
        order_dt: '',
        canceled_dt: '',
        os_code: '',
      }
      this.clearSelectedOrder()
      this.clearOrderStatusMaster()
    },

    submit() {
      if (this.item.os_code === this.selectedOrder.os_code) {
        this.closeForm()
      } else {
        this.submitStatus = true
        this.setAbsoluteOverlayStatus(true)
        this.editCanceledStatus(this.item).then(() => {
          this.closeForm()
        }).catch(error => {
          if (error.response) {
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          }
        }).finally(() => {
          this.setAbsoluteOverlayStatus(false)
          this.submitStatus = false
        })
      }
    },
  },
}
</script>
