<template>
  <div id="order-by-member-list">
    <v-card>
      <v-card-title>キャンセルした注文（一覧）</v-card-title>
      <v-row class="px-2 ma-0">
        <v-col cols="12" md="3">
          <v-menu
            v-model="orderDateStartMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="orderStartDate"
                placeholder="注文日From"
                dense
                hide-details
                outlined
                readonly
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="orderStartDate" @input="orderDateStartMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="3">
          <v-menu
            v-model="orderDateEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="orderEndDate"
                placeholder="注文日To"
                dense
                outlined
                readonly
                hide-details
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="orderEndDate"
              @input="orderDateEndMenu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" md="3">
          <v-menu
            v-model="cancelDateStartMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="cancelStartDate"
                placeholder="キャンセル日From"
                dense
                hide-details
                outlined
                readonly
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="cancelStartDate" @input="cancelDateStartMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="3">
          <v-menu
            v-model="cancelDateEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="cancelEndDate"
                placeholder="キャンセル日To"
                dense
                outlined
                readonly
                hide-details
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="cancelEndDate"
              @input="cancelDateEndMenu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="search"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="secondary"
            outlined
            class="mb-4 me-3"
          >
            <v-icon size="17" class="me-1">
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="CanceledList"
        sort-by
        class="elevation-1"
        :search="search"
      >
        <template v-slot:[`item.ord_id`]="{ item }">
          <a
            href="javascript:void(0);"
            @click="pushOrderHistory(item)"
          >{{ item.ord_id }}</a>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a
                v-bind="attrs"
                href="javascript:void(0);"
                :style="item.mbr_status === 9 ? 'text-decoration: line-through red; color:#696969' : 'text-decoration: underline; color:#696969' "
                v-on="on"
              >{{ item.mbr_sei }} {{ item.mbr_mei }}</a>
            </template>
            <span>{{ item.mbr_sei }}   {{ item.mbr_mei }}</span><br>
            <span>{{ item.mbr_sei_kana }}   {{ item.mbr_mei_kana }}</span><br>
            <span>{{ item.mbr_zip }}</span><br>
            <span>{{ item.mbr_address1 }}{{ item.mbr_address2 }}</span><br>
            <span>{{ item.mbr_email }}</span><br>
            <span>{{ item.mbr_mobile }}</span><br>
          </v-tooltip>
        </template>
        <template v-slot:[`item.order_dt`]="{ item }">
          <div>
            <span>{{ item.order_dt }}</span>
          </div>
        </template>
        <template v-slot:[`item.os_desc`]="{ item }">
          <div>
            {{ item.os_desc }}
          </div>
        </template>
        <template v-slot:[`item.order_total`]="{ item }">
          <label>{{ item.order_total ? item.order_total.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : "" }}</label>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <StatusDialog
              :id="item.ord_id"
              class="py-2 pl-1"
              @refresh="loadData"
            ></StatusDialog>
          </div>
        </template>
        <!-- <template v-slot:[`item.order_code`]="{ item }">
          <v-tooltip v-if="item.order_code" bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              > {{ item.order_code }}</span>
            </template>
            <span>{{ item.type }}<br />{{ item.product_name }}<br />{{ item.price }}</span>
          </v-tooltip>
        </template> -->
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex'
import {
  mdiCalendar,
  mdiPencil,
  mdiExportVariant,
} from '@mdi/js'

import StatusDialog from '@/views/bimi/order/OrderCancel/StatusDialog.vue'

export default {
  components: {
    StatusDialog,
  },
  data: () => ({
    orderStartDate: '',
    cancelStartDate: '',
    orderEndDate: '',
    cancelEndDate: '',
    orderDateEndMenu: '',
    cancelDateEndMenu: '',
    orderDateStartMenu: '',
    cancelDateStartMenu: '',
    search: '',
    icons: {
      mdiCalendar,
      mdiPencil,
      mdiExportVariant,
    },
    headers: [
      {
        text: '注文ID',
        value: 'ord_id',
        align: 'left',
        width: '8%',
        fixed: true,
      },

      // {
      //   text: '注文番号',
      //   value: 'order_no',
      //   align: 'left',
      //   width: '14%',
      //   fixed: true,
      // },
      {
        text: '会員氏名',
        align: 'left',
        sortable: true,
        value: 'name',
        width: '12%',
        fixed: true,
      },
      {
        text: '商品名',
        value: 'product_name',
        width: '25%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '総額',
        value: 'order_total',
        width: '10%',
        align: 'right',
        sortable: true,
        fixed: true,
      },
      {
        text: '注文日時',
        value: 'order_dt',
        width: '12%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: 'キャンセル日時',
        value: 'canceled_dt',
        width: '14%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '注文状態',
        value: 'os_desc',
        width: '10%',
        align: 'center',
        sortable: true,
        fixed: true,
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        width: '5%',
        align: 'right',
        fixed: true,
      },
      {
        text: 'メールアドレス',
        value: 'mbr_email',
        align: ' d-none',
      },
      {
        text: '姓',
        value: 'mbr_sei',
        align: ' d-none',
      },
      {
        text: '名',
        value: 'mbr_mei',
        align: ' d-none',
      },
      {
        text: 'セイ',
        value: 'mbr_sei_kana',
        align: ' d-none',
      },
      {
        text: 'メイ',
        value: 'mbr_mei_kana',
        align: ' d-none',
      },
      {
        text: '電話番号',
        value: 'mbr_mobile',
        align: ' d-none',
      },
      {
        text: '注文番号',
        value: 'order_no',
        align: ' d-none',
      },
    ],
    orderStatuses: [],
  }),
  computed: {
    ...mapState('orderStore', ['CanceledList']),
  },
  watch: {
    orderStartDate(newValue) {
      this.changeRouteQuery('orderStartDate', newValue)
      this.loadData()
    },
    cancelStartDate(newValue) {
      this.changeRouteQuery('cancelStartDate', newValue)
      this.loadData()
    },
    orderEndDate(newValue) {
      this.changeRouteQuery('orderEndDate', newValue)
      this.loadData()
    },
    cancelEndDate(newValue) {
      this.changeRouteQuery('cancelEndDate', newValue)
      this.loadData()
    },
  },
  created() {
    this.loadQuery()
    this.changeRouteQuery()
    this.loadData()
  },
  destroyed() {
    this.clearCanceledList()
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapMutations('orderStore', ['clearCanceledList']),
    ...mapActions('orderStore', ['loadCanceledList']),
    pushOrderHistory(item) {
      this.$router.push({
        name: 'order-history',
        params: {
          id: item.ord_id,
        },
        query: {
          from: 'cancel',
        },
      })
    },
    loadData() {
      this.setOverlayStatus(true)
      this.loadCanceledList(this.$route.query).finally(() => {
        this.setOverlayStatus(false)
      })
    },

    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }

      this.$router.push({
        path: this.$route.path, query,
      })
    },

    loadQuery() {
      this.orderStartDate = this.$route.query.orderStartDate ?? ''
      this.cancelStartDate = this.$route.query.cancelStartDate ?? ''
      this.orderEndDate = this.$route.query.orderEndDate ?? ''
      this.cancelEndDate = this.$route.query.cancelEndDate ?? ''
    },
  },
}
</script>
<style>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
.v-input__prepend-outer {
  margin: auto !important;
}
.text-field-dense-append-prepend-margin {
  margin: auto;
}
.v-input__append-inner {
  margin: auto !important;
}
/* .v-data-table tr:nth-child(odd) td {
  background-color: #F0FFFF;
} */
</style>
